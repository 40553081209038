<template>
  <base-layout>
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link :to="{ name: 'home' }">
            <span class="icon is-small">
              <font-awesome-icon icon="home" />
            </span>
            <span>Home</span></router-link
          >
        </li>
        <li class="is-active">
          <router-link
            :to="{ name: 'account', params: { id: $route.params.id } }"
          >
            <span class="icon is-small">
              <font-awesome-icon icon="user-friends" />
            </span>
            <span>Negotiator</span></router-link
          >
        </li>
      </ul>
    </nav>
    <div class="content">
      <user-info />
    </div>
    <hr class="is-marginless" />
    <account-header />
    <div class="columns">
      <div class="column is-one-quarter is-hidden-mobile">
        <sidebar />
      </div>
      <div class="column">
        <router-view />
      </div>
    </div>
  </base-layout>
</template>
<script>
import BaseLayout from "@/components/BaseLayout.vue";
import UserInfo from "@/components/UserInfo.vue";
import { mapState } from "vuex";
import { mapActions } from "vuex";
import AccountHeader from "@/components/AccountHeader.vue";
import Sidebar from "@/components/Sidebar.vue";

export default {
  components: { BaseLayout, UserInfo, AccountHeader, Sidebar },
  computed: {
    ...mapState(["instanceConfig"])
  },
  methods: {
    ...mapActions(["fetchPaymentPortalInstanceConfig", "fetchUserInfo"]),
    ...mapActions("accounts", ["fetchAccounts"])
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fetchPaymentPortalInstanceConfig(vm.$route.params.instanceId);
      if (
        vm.$store.state.userInfo == null ||
        vm.$store.state.userInfo.firstName == null
      ) {
        vm.fetchUserInfo();
      }
      if (
        vm.$store.state.accounts.accounts == null ||
        vm.$store.state.accounts.accounts.length == 0
      ) {
        vm.fetchAccounts();
      }
    });
  }
};
</script>
